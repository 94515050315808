import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainMarketingBanner from "../sections/main-marketing-banner"
import OurTeamHomeBanner from "../sections/our-team/our-team-home-banner"
import MidwivesBanner from "../sections/our-team/midwives-banner"
import TeamTestimonialsBanner from "../sections/our-team/team-testimonial-banner"
import CouncilsBanner from "../sections/our-team/councils-banner"
import { useIntl } from "gatsby-plugin-react-intl"
import AboutUsBanner from "../sections/our-team/about-us-banner"

const OurTeamPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout footerCms={data.strapiFooter}>
      <SEO
        lang={intl.locale}
        title={data.strapiOurTeam.seo.title}
        description={data.strapiOurTeam.seo.description}
        keywords={data.strapiOurTeam.seo.keywords}
      />
      <OurTeamHomeBanner
        cms={data.strapiOurTeam.homeBanner}
      ></OurTeamHomeBanner>
      <AboutUsBanner cms={data.strapiOurTeam.aboutUsBanner}></AboutUsBanner>
      <TeamTestimonialsBanner
        cms={data.allStrapiManagement}
      ></TeamTestimonialsBanner>
      <CouncilsBanner cms={data.allStrapiCouncil}></CouncilsBanner>
      <MidwivesBanner cms={data.allStrapiMidwife}></MidwivesBanner>
      <MainMarketingBanner
        cms={data.strapiMain.marketingBanner}
      ></MainMarketingBanner>
    </Layout>
  )
}

export const query = graphql`
  query OurTeam($language: String) {
    strapiOurTeam(locale: { eq: $language }) {
      seo {
        title
        description
        keywords {
          tag
        }
      }
      homeBanner {
        title
        content
      }
      aboutUsBanner {
        title
        content
        media {
          localFile {
            publicURL
          }
        }
      }
    }
    strapiMain(locale: { eq: $language }) {
      marketingBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
    }
    strapiFooter(locale: { eq: $language }) {
      company_name
      company_address
      call_center
      contact
      data_administrator
      rights_reserved
      page_links {
        title
        link
      }
      document_links {
        title
        link
      }
    }
    allStrapiManagement(
      filter: { locale: { eq: $language } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          position
          name
          surname
          title
          description
          short_description
          avatar {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allStrapiMidwife(
      filter: { locale: { eq: $language } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          position
          name
          description
          avatar {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allStrapiCouncil(filter: { locale: { eq: $language } }) {
      edges {
        node {
          id
          name
          title
          description
          avatar {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default OurTeamPage
