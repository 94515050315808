import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Fade from "react-reveal/Fade"
import SingleCouncil from "../../components/singleCouncil"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const CouncilsBanner = ({ cms }) => {
  return (
    <Box
      className="councils-banner"
      component="section"
      display="flex"
      alignItems="center"
      textAlign="center"
    >
      <Container>
        <h1>
          <FormattedMessage
            id="councilsBanner_Title"
            defaultMessage="Konsultanci"
          />
        </h1>
        {cms.edges.map((element, index) => {
          return (
            <Fade fraction={0.3} duration={1250}>
              <SingleCouncil
                council={element.node}
                index={index}
              ></SingleCouncil>
            </Fade>
          )
        })}
      </Container>
    </Box>
  )
}

export default CouncilsBanner
