import React from "react"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import { XCircle } from "react-bootstrap-icons"

import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Markdown from "react-markdown"
import Box from "@material-ui/core/Box"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CardMidwife = ({ midwife }) => {
  const [openMidwife, setOpenMidwife] = React.useState(false)

  const handleClickOpen = () => {
    setOpenMidwife(true)
  }
  const handleClose = () => {
    setOpenMidwife(false)
  }

  return (
    <>
      <Box
        className="midwife-card"
        flexGrow={0}
        flexBasis={"20%"}
        px={2}
        py={2}
        mx={1}
        my={1}
      >
        <div className="midwife-card__image-area">
          <img
            src={midwife.avatar.localFile.publicURL}
            alt="Midwife avatar"
          ></img>
        </div>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <p className="midwife-card__name">{midwife.name}</p>
          <a className="circle_more-button" onClick={handleClickOpen}>
            <svg height="48px" width="48px">
              <g>
                <line x1="14" x2="34" y1="24" y2="24"></line>
                <line x1="24" x2="24" y1="14" y2="34"></line>
                <circle cx="24" cy="24" r="20"></circle>
              </g>
            </svg>
          </a>
        </Box>
      </Box>

      <Dialog
        className="testimonial-dialog"
        maxWidth={"lg"}
        open={openMidwife}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle className="testimonial-dialog__title">
          <XCircle
            className="testimonial-dialog__close-circle"
            onClick={handleClose}
            size={24}
          />
        </DialogTitle>
        <DialogContent className="testimonial-dialog__body belly-scrollbar">
          <h2>{midwife.name}</h2>
          <Markdown allowDangerousHtml>{midwife.description}</Markdown>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default CardMidwife
