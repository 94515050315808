import React from "react"
import PropTypes from "prop-types"
import SingleTeamTestimonial from "./singleTeamTestimonial"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.scss"

// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper/core"

// install Swiper modules
SwiperCore.use([Autoplay])

const TeamCarousel = ({ data, swipperCreated }) => {
  return (
    <>
      <Swiper
        className="testimonial-carousel"
        onSwiper={swiper => swipperCreated(swiper)}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          1250: {
            slidesPerView: 2.25,
            spaceBetween: 50,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {data.map((element, index) => {
          return (
            <SwiperSlide key={index} className="testimonial-carousel__slide">
              <SingleTeamTestimonial
                testimonial={element.node}
              ></SingleTeamTestimonial>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

TeamCarousel.propTypes = {
  data: PropTypes.array,
  swipperCreated: PropTypes.func,
}

TeamCarousel.defaultProps = {
  data: [],
}

export default TeamCarousel
