import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import CardMidwife from "../../components/cardMidwife"
import Fade from "react-reveal/Fade"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const MidwivesBanner = ({ cms }) => {
  return (
    <Box
      className="midwives-banner"
      component="section"
      display="flex"
      alignItems="center"
      textAlign="center"
    >
      <Container>
        <h1>
          <FormattedMessage
            id="midwivesBanner_Title"
            defaultMessage="Położne"
          />
        </h1>
        <Box
          className="midwives-banner__cards-grid"
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
        >
          {cms.edges.map((element, index) => {
            return (
              <Fade fraction={0.5} duration={1250}>
                <CardMidwife midwife={element.node}></CardMidwife>
              </Fade>
            )
          })}
        </Box>
      </Container>
    </Box>
  )
}

export default MidwivesBanner
