import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Markdown from "react-markdown"

const SingleCouncil = props => {
  const { council, index } = props

  let councilAvatar = (
    <Box
      className="single-council__image-area"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img
        src={council.avatar.localFile.publicURL}
        alt="Avatar of council"
      ></img>
    </Box>
  )

  let councilDescription = (
    <Box className="single-council__text-area">
      <h2 className="single-council__name">{council.name}</h2>
      <p className="single-council__title">{council.title}</p>
      <Markdown allowDangerousHtml className="single-council__description">
        {council.description}
      </Markdown>
    </Box>
  )

  let leftBanner = index % 2 == 0 ? councilAvatar : councilDescription
  let rightBanner = index % 2 == 0 ? councilDescription : councilAvatar

  return (
    <>
      <Box
        className="single-council"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Grid container>
          <Grid
            item
            className="single-council-banner__left"
            xs={12}
            lg={index % 2 == 0 ? 5 : 7}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {leftBanner}
            </Box>
          </Grid>
          <Grid
            item
            className="single-council-banner__right"
            xs={12}
            lg={index % 2 == 0 ? 7 : 5}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {rightBanner}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SingleCouncil
