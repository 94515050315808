import React, { useState } from "react"
import TeamCarousel from "../../components/teamCarousel"
import CarouselSterring from "../../components/carouselSterring"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

import hearthWrapper from "../../assets/testimonial-hearth.png"

import Fade from "react-reveal/Fade"

import { FormattedMessage } from "gatsby-plugin-react-intl"

const TeamTestimonialsBanner = ({ cms }) => {
  const [swiper, setSwiper] = useState(null)

  const onSwiperCreated = swiper => {
    setSwiper(swiper)
  }

  const onSwipeNext = () => {
    if (swiper) {
      swiper.slideNext()
    }
  }

  const onSwipePrev = () => {
    if (swiper) {
      swiper.slidePrev()
    }
  }

  return (
    <>
      <section className="team-testimonials-banner">
        <img
          className="hearth-wrapper"
          src={hearthWrapper}
          alt="Ipad with carebits app view"
        ></img>
        <Container maxWidth="xl" disableGutters={true}>
          <Grid container>
            <Grid item xs={12} lg={4} className="banner-left">
              <h1>
                <FormattedMessage
                  id="teamTestimonialBanner_Title"
                  defaultMessage="Nasz zespół"
                />
              </h1>
              <p>
                <FormattedMessage
                  id="teamTestimonialBanner_Subtitle"
                  defaultMessage="Poznaj nasz wspaniały zespół"
                />
              </p>
              <CarouselSterring
                swipeNext={onSwipeNext}
                swipePrev={onSwipePrev}
              ></CarouselSterring>
            </Grid>
            <Grid item xs={12} lg={8} className="banner-right">
              <Fade right fraction={0.5}>
                <TeamCarousel
                  data={cms.edges}
                  swipperCreated={onSwiperCreated}
                ></TeamCarousel>
              </Fade>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  )
}

export default TeamTestimonialsBanner
