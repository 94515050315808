import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Fade from "react-reveal/Fade"
import SingleSideSection from "../../components/singleSideSection"
const AboutUsBanner = ({ cms }) => {
  return (
    <Box
      className="about-us-banner"
      component="section"
      display="flex"
      alignItems="center"
      textAlign="center"
    >
      <Container>
        {cms.map((element, index) => {
          return (
            <Fade fraction={0.3} duration={1250}>
              <SingleSideSection
                section={element}
                index={index}
              ></SingleSideSection>
            </Fade>
          )
        })}
      </Container>
    </Box>
  )
}

export default AboutUsBanner
