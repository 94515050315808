import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Markdown from "react-markdown"

const SingleSideSection = props => {
  const { section, index } = props

  let media = (
    <Box
      className="single-side-section__image-area"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img
        src={section.media.localFile.publicURL}
        alt="Avatar of section"
      ></img>
    </Box>
  )

  let content = (
    <Box className="single-side-section__text-area">
      <Markdown allowDangerousHtml className="single-side-section__title">
        {section.title}
      </Markdown>
      <Markdown allowDangerousHtml className="single-side-section__content">
        {section.content}
      </Markdown>
    </Box>
  )

  let leftBanner = index % 2 == 0 ? media : content
  let rightBanner = index % 2 == 0 ? content : media

  return (
    <>
      <Box
        className="single-side-section"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Grid container>
          <Grid
            item
            className="single-side-section-banner__left"
            xs={12}
            lg={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {leftBanner}
            </Box>
          </Grid>
          <Grid
            item
            className="single-side-section-banner__right"
            xs={12}
            lg={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {rightBanner}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SingleSideSection
