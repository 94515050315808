import React from "react"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import { XCircle } from "react-bootstrap-icons"

import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Markdown from "react-markdown"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SingleTeamTestimonial = ({ testimonial }) => {
  const [openTestimonial, setOpenTestimonial] = React.useState(false)

  const handleClickOpen = () => {
    setOpenTestimonial(true)
  }
  const handleClose = () => {
    setOpenTestimonial(false)
  }

  return (
    <>
      <div className="single-testimonial">
        <div className="single-testimonial__image-area">
          <img
            src={testimonial.avatar.localFile.publicURL}
            alt="Testimonial avatar"
          ></img>
        </div>
        <h2 className="single-testimonial__name">
          {testimonial.name} {testimonial.surname}
        </h2>
        <p className="single-testimonial__title">{testimonial.title}</p>
        <p className="single-testimonial__description">
          {testimonial.short_description}
        </p>
        <a className="circle_more-button" onClick={handleClickOpen}>
          <svg height="48px" width="48px">
            <g>
              <line x1="14" x2="34" y1="24" y2="24"></line>
              <line x1="24" x2="24" y1="14" y2="34"></line>
              <circle cx="24" cy="24" r="20"></circle>
            </g>
          </svg>
        </a>
      </div>

      <Dialog
        className="testimonial-dialog"
        maxWidth={"lg"}
        open={openTestimonial}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle className="testimonial-dialog__title">
          <XCircle
            className="testimonial-dialog__close-circle"
            onClick={handleClose}
            size={24}
          />
        </DialogTitle>
        <DialogContent className="testimonial-dialog__body belly-scrollbar">
          <img
            className="testimonial-dialog__avatar-image"
            src={testimonial.avatar.localFile.publicURL}
          ></img>
          <h2>
            {testimonial.name} {testimonial.surname}
          </h2>
          <p>{testimonial.title}</p>
          <Markdown allowDangerousHtml>{testimonial.description}</Markdown>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SingleTeamTestimonial
